/* global Office */
import { Observable } from "@zebrabi/observable";
import * as process from "process";
import AlternativeLoginRequiredException from "../../exceptions/AlternativeLoginRequiredException";
import SilentLoginFailedException from "../../exceptions/SilentLoginFailedException";
import ActionObserver from "../../observers/ActionObserver";
import ActionSubject from "../../subject/ActionSubject";
import { ActionType } from "../../ActionType";
import { UNLOCK_ALL_FEATURES_URL, WHY_SIGN_IN_NEEDED_URL } from "../../constants";
import Overlay from "../Overlay";
import { msalManager } from "./MsalManager";
import { officeTokenManager } from "./OfficeTokenManager";
import { ProductType } from "@zebrabi/licensing/licensing.types";
import { openURL } from "@zebrabi/licensing/helpers";

export const ACTION_SIGNED_OUT = "signed-out";
export class MSFTSignIn {
  private accessToken: string;
  private actionSubject: ActionSubject = new ActionSubject();

  private overlay = new Overlay();
  private static _instance: MSFTSignIn = new MSFTSignIn();
  private sharepointAccessToken: string = null;
  public sharepointTokenSubject: Observable<string> = new Observable<string>(this.sharepointAccessToken);

  private constructor() { }

  public async authenticateWithFilePermissions(organisationID: string) {
    let accessToken: string;
    const scopes = ["openid", "User.Read", "Sites.Read.All", "Files.Read.All"];
    if (this.sharepointAccessToken) {
      return this.sharepointAccessToken;
    }

    try {
      await msalManager.acquireTokenSilent(scopes).then((response) => {
        accessToken = response.accessToken;
      }).catch(async (error) => {
        console.error("acquireTokenSilent failed: ", error);
        await msalManager.silentLogin(scopes).then((response) => {
          accessToken = response;
        }).catch((error) => {
          console.error("Silent login failed: ", error);
          throw new SilentLoginFailedException("Silent login failed");
        });
      });

      // const silentResponse = await msalManager.acquireTokenSilent(scopes);
      // accessToken = silentResponse?.accessToken;
      // if (!accessToken) {
      //   accessToken = await msalManager.silentLogin(scopes);
      //   console.debug("silentLogin access token: ", accessToken);
      // }

      // const officeAccessToken = await Office.auth.getAccessToken({ // possibly Office.auth.getAccessToken could be used here?
      //   allowSignInPrompt: false,
      //   allowConsentPrompt: false,
      //   forMSGraphAccess: true,
      // });
      // console.debug("Office access token: ", officeAccessToken);

    } catch (error) {
      console.debug("Silent login failed: ", error);
      accessToken = await msalManager.login(
        scopes,
        "sync=true",
        `tid=${organisationID}`,
        `signed-out=${localStorage?.getItem(ACTION_SIGNED_OUT)}`
      );
    }

    if (accessToken) {
      this.sharepointAccessToken = accessToken;
      this.sharepointTokenSubject.setAndUpdate(accessToken);
    } else {
      throw new SilentLoginFailedException("Silent login failed");
    }
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public isSignedIn(): boolean {
    return !!this.accessToken;
  }

  public isFilePermissionGranted(): boolean {
    return !!this.sharepointAccessToken;
  }

  public async silentSignIn() {
    if (localStorage?.getItem(ACTION_SIGNED_OUT) === "true") {
      throw new SilentLoginFailedException("User manually singed out! Manual login required.");
    }

    if (process.env.DEBUG_LOG === "true") {
      console.debug("Trying to sign in silently");
    }
    
    this.accessToken = await officeTokenManager.getSilentAccessToken().catch(async () => { 
      // try to acquire token silently
      const allAccounts = await msalManager.getAllAccounts();
      if (allAccounts.length === 1) {
        return (await msalManager.acquireTokenSilent()).accessToken;
      }
      return await msalManager.silentLogin();
    });

    if (this.accessToken) {
      this.actionSubject.notify(this.accessToken, ActionType.SignInSuccess);
      return this.accessToken;
    } else {
      throw new SilentLoginFailedException("Could not get silent token");
    }
  }

  public async signIn(): Promise<string> {
    if (this.accessToken) {
      this.actionSubject.notify(this.accessToken, ActionType.SignInSuccess);
      return this.accessToken;
    }

    try {
      this.accessToken = await this.silentSignIn();
    } catch (error) {
      if (process.env.DEBUG_LOG === "true") {
        console.debug("Silent sign in failed: ", error);
      }
    }

    if (this.accessToken) {
      this.actionSubject.notify(this.accessToken, ActionType.SignInSuccess);
      return this.accessToken;
    }

    if (process.env.DEBUG_LOG === "true") {
      console.debug("Trying classic sign in");
    }

    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
      this.accessToken = await msalManager.login();
    } else {
      try {
        this.accessToken = await officeTokenManager.getAccessToken();
      } catch (error) {
        if (error instanceof AlternativeLoginRequiredException) {
          this.accessToken = await msalManager.login();
        } else {
          console.error("Error getting token", error);
          this.renderErrorSignIn();
        }
      }
    }

    if (this.accessToken) {
      this.actionSubject.notify(this.accessToken, ActionType.SignInSuccess);
      localStorage?.setItem(ACTION_SIGNED_OUT, "false");
      return this.accessToken;
    }

    this.renderErrorSignIn();

    return undefined;
  }

  public async signOut(): Promise<void> {
    if (process.env.DEBUG_LOG === "true") {
      console.debug("Sign out");
    }

    this.accessToken = null;
    localStorage?.setItem(ACTION_SIGNED_OUT, "true");
    this.actionSubject.notify(JSON.stringify({ success: "ok" }), ActionType.SignOut);
    this.renderSignIn();
  }

  public renderSignIn() {
    this.overlay.close();
    this.overlay.overlayConfig = {
      copy: "Please sign in to start editing <br> Zebra BI for Office",
      confirm: null,
      buy: null,
      footer: {
        // link: UNLOCK_ALL_FEATURES_URL
        //   .replace("$host", Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint)
        //   .replace("$visual", process.env.ZBI_VISUAL),
        link: WHY_SIGN_IN_NEEDED_URL.replace("$host", Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint),
        text: "Why do I need to sign in?"
      },
      signIn: "Sign in",
      trial: null,
    };
    document.body.append(this.overlay);
    const signInButton = this.overlay.querySelector(".sign-in");

    signInButton?.addEventListener("click", async () => {
      await this.signInButtonClickHandler();
    });

    const learnMore = this.overlay.querySelector("footer a");
    learnMore?.addEventListener("click", () => {
      openURL(this.overlay.overlayConfig.footer.link);
    });
  }

  public renderErrorSignIn() {
    this.overlay.close();
    this.overlay.overlayConfig = {
      copy: "Error encountered while attempting to sign in. <br> Please retry sign in.",
      confirm: null,
      buy: null,
      footer: {
        link: UNLOCK_ALL_FEATURES_URL
          .replace("$host", Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint)
          .replace("$visual", process.env.ZBI_VISUAL),
        text: "Why do I need to sign in?"
      },
      signIn: "Retry signing in",
      trial: null,
    };
    document.body.append(this.overlay);
    const signInButton = this.overlay.querySelector(".sign-in");

    signInButton?.addEventListener("click", async () => {
      await this.signInButtonClickHandler();
    });

    const learnMore = this.overlay.querySelector("footer a");
    learnMore?.addEventListener("click", () => {
      openURL(this.overlay.overlayConfig.footer.link);
    });
  }

  private async signInButtonClickHandler() {
    await this.signIn();

    if (this.isSignedIn()) {
      this.overlay.close();
    }
  }

  public attach(observer: ActionObserver) {
    this.actionSubject.attach(observer);
  }

  public static getInstance() {
    return this._instance;
  }

  getSharepointToken() {
    return this.sharepointAccessToken;
  }
}

export const signInService = MSFTSignIn.getInstance();
