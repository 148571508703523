export function initIconSprites(): void {
    const sprites = document.querySelector("#data-linking-icon-sprites");
    if(!sprites) {
        const range = document.createRange();
        const icons = range.createContextualFragment(iconsHtml);
        document.body.append(icons);
    }
}

export enum IconsDataLinking {
    QuestionCircle = "question-circle",
    InfoCircle = "info-circle",
    ErrorCircle = "error-circle",
    CheckmarkCircle = "checkmark-circle",
    ArrowSync = "arrow-sync",
    EditLink = "edit-link",
    RemoveLink = "remove-link",
}

const iconsHtml = `
    <svg class=" svg icons-view hidden" id="data-linking-icon-sprites">

        <symbol id="icon-info-circle">
            <path d="M8.49206 6.91012C8.44972 6.67687 8.24557 6.49999 8.00011 6.49999C7.72397 6.49999 7.50011 6.72385 7.50011 6.99999V11.5021L7.50817 11.592C7.55051 11.8253 7.75465 12.0021 8.00011 12.0021C8.27626 12.0021 8.50011 11.7783 8.50011 11.5021V6.99999L8.49206 6.91012ZM8.79883 4.74999C8.79883 4.33578 8.46304 3.99999 8.04883 3.99999C7.63461 3.99999 7.29883 4.33578 7.29883 4.74999C7.29883 5.16421 7.63461 5.49999 8.04883 5.49999C8.46304 5.49999 8.79883 5.16421 8.79883 4.74999ZM16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z" fill="#242424"/>
        </symbol>

        <symbol id="icon-question-circle">
            <path d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM8 11.5C8.41421 11.5 8.75 11.8358 8.75 12.25C8.75 12.6642 8.41421 13 8 13C7.58579 13 7.25 12.6642 7.25 12.25C7.25 11.8358 7.58579 11.5 8 11.5ZM8 3.5C9.38071 3.5 10.5 4.61929 10.5 6C10.5 6.72959 10.1848 7.40774 9.6513 7.8771L9.49667 8.00243L9.27817 8.16553L9.19065 8.23718C9.1348 8.28509 9.08354 8.33373 9.03456 8.38592C8.69627 8.74641 8.5 9.24223 8.5 10C8.5 10.2761 8.27614 10.5 8 10.5C7.72386 10.5 7.5 10.2761 7.5 10C7.5 8.98796 7.79312 8.24747 8.30535 7.70162C8.41649 7.5832 8.53202 7.47988 8.66094 7.37874L8.90761 7.19439L9.02561 7.09468C9.325 6.81435 9.5 6.42206 9.5 6C9.5 5.17157 8.82843 4.5 8 4.5C7.17157 4.5 6.5 5.17157 6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 4.61929 6.61929 3.5 8 3.5Z" fill="#242424"/>
        </symbol>

        <symbol id ="icon-error-circle">
            <path d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5ZM8 4C7.75454 4 7.55039 4.17688 7.50806 4.41012L7.5 4.5V9L7.50806 9.08988C7.55039 9.32312 7.75454 9.5 8 9.5C8.24546 9.5 8.44961 9.32312 8.49194 9.08988L8.5 9V4.5L8.49194 4.41012C8.44961 4.17688 8.24546 4 8 4Z" fill="#DA3B01"/>
        </symbol>

        <symbol id="icon-checkmark-circle">
            <path d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM11.3584 5.64645C11.1849 5.47288 10.9154 5.4536 10.7206 5.58859L10.6513 5.64645L7 9.298L5.35355 7.65131L5.28431 7.59346C5.08944 7.45846 4.82001 7.47775 4.64645 7.65131C4.47288 7.82488 4.4536 8.09431 4.58859 8.28917L4.64645 8.35842L6.64645 10.3584L6.71569 10.4163C6.8862 10.5344 7.1138 10.5344 7.28431 10.4163L7.35355 10.3584L11.3584 6.35355L11.4163 6.28431C11.5513 6.08944 11.532 5.82001 11.3584 5.64645Z" fill="#56B60E"/>
        </symbol>

        <symbol id="icon-arrow-sync">
            <path d="M13.2135 7.90356C13.1897 6.59347 12.681 5.29253 11.6872 4.2934C9.65082 2.24629 6.34926 2.24629 4.31292 4.2934C4.13365 4.47362 3.97038 4.66341 3.82299 4.86104C3.61569 5.139 3.2235 5.19539 2.947 4.987C2.6705 4.7786 2.6144 4.38434 2.8217 4.10637C3.00454 3.8612 3.20668 3.6263 3.42801 3.40381C5.95307 0.865396 10.047 0.865396 12.5721 3.40381C13.6961 4.53375 14.3198 5.97715 14.4431 7.45349L14.9318 6.96216C15.1762 6.71651 15.5724 6.71651 15.8167 6.96216C16.0611 7.20782 16.0611 7.6061 15.8167 7.85175L14.0469 9.63093C13.8026 9.87658 13.4064 9.87658 13.162 9.63093L11.3922 7.85175C11.1478 7.6061 11.1478 7.20782 11.3922 6.96216C11.6365 6.71651 12.0327 6.71651 12.2771 6.96216L13.2135 7.90356ZM2.78657 8.09644C2.81036 9.40652 3.31908 10.7075 4.31295 11.7066C6.34929 13.7537 9.65085 13.7537 11.6872 11.7066C11.8665 11.5264 12.0297 11.3366 12.1771 11.139C12.3844 10.861 12.7766 10.8046 13.0531 11.013C13.3296 11.2214 13.3857 11.6157 13.1784 11.8936C12.9956 12.1388 12.7934 12.3737 12.5721 12.5962C10.047 15.1346 5.9531 15.1346 3.42804 12.5962C2.30405 11.4663 1.68033 10.0228 1.55703 8.54651L1.06829 9.03783C0.823926 9.28349 0.427738 9.28349 0.183378 9.03783C-0.0609819 8.79218 -0.0609819 8.3939 0.183378 8.14825L1.9532 6.36907C2.19756 6.12342 2.59374 6.12342 2.8381 6.36907L4.60792 8.14825C4.85228 8.3939 4.85228 8.79218 4.60792 9.03783C4.36356 9.28349 3.96737 9.28349 3.72301 9.03783L2.78657 8.09644Z" fill="#0078D4"/>
        </symbol>

        <symbol id="icon-edit-link">
            <path d="M6.49928 2.49994C6.49928 2.22383 6.27545 2 5.99934 2H3.99956L3.78011 2.00592C1.67328 2.11984 0 3.86426 0 5.9995C0 8.20836 1.79066 9.99899 3.99956 9.99899H5.99934L6.0892 9.99094C6.32243 9.94861 6.49928 9.74449 6.49928 9.49906C6.49928 9.22295 6.27545 8.99912 5.99934 8.99912H3.99956L3.80233 8.99274C2.2376 8.89118 0.99989 7.58988 0.99989 5.9995C0.99989 4.34285 2.34289 2.99987 3.99956 2.99987H5.99934L6.0892 2.99182C6.32243 2.94949 6.49928 2.74537 6.49928 2.49994ZM15.9982 5.9995C15.9982 3.79064 14.2076 2 11.9987 2H9.9989L9.90903 2.00805C9.67581 2.05039 9.49895 2.25451 9.49895 2.49994C9.49895 2.77604 9.72279 2.99987 9.9989 2.99987H11.9987L12.1959 3.00625C13.7606 3.10781 14.9983 4.40912 14.9983 5.9995C14.9983 6.04406 14.9974 6.0884 14.9955 6.13249C15.3347 6.23946 15.658 6.41095 15.9461 6.64697C15.9804 6.43622 15.9982 6.21994 15.9982 5.9995ZM11.9987 5.49956H3.99956L3.90969 5.50761C3.67647 5.54994 3.49961 5.75407 3.49961 5.9995C3.49961 6.2756 3.72345 6.49943 3.99956 6.49943H11.9987L12.0885 6.49138C12.3218 6.44905 12.4986 6.24493 12.4986 5.9995C12.4986 5.72339 12.2748 5.49956 11.9987 5.49956ZM7.97887 12.3759L12.8078 7.54706C13.538 6.81681 14.722 6.81681 15.4523 7.54706C16.1826 8.27732 16.1826 9.46129 15.4523 10.1916L10.6234 15.0204C10.3419 15.3019 9.98909 15.5016 9.60282 15.5982L8.10508 15.9726C7.45374 16.1355 6.86376 15.5455 7.02659 14.8942L7.40103 13.3965C7.4976 13.0102 7.69733 12.6574 7.97887 12.3759Z" fill="#242424"/>
        </symbol>

        <symbol id="icon-remove-link">
            <path d="M5.64706 2C5.90696 2 6.11765 2.20893 6.11765 2.46667C6.11765 2.69576 5.95118 2.8863 5.73165 2.92581L5.64706 2.93333H3.76471C2.20531 2.93333 0.941176 4.18694 0.941176 5.73333C0.941176 7.21787 2.10621 8.43258 3.57906 8.52738L3.76471 8.53333H5.64706C5.90696 8.53333 6.11765 8.74227 6.11765 9C6.11765 9.2291 5.95118 9.41963 5.73165 9.45915L5.64706 9.46667H3.76471C1.68552 9.46667 0 7.7952 0 5.73333C0 3.7402 1.57502 2.11186 3.55815 2.00552L3.76471 2H5.64706ZM11.2941 2C13.3733 2 15.0588 3.67147 15.0588 5.73333C15.0588 6.37602 14.8951 6.98078 14.6067 7.50881C14.3455 7.33834 14.0678 7.1907 13.7764 7.06874C13.994 6.67182 14.1176 6.21689 14.1176 5.73333C14.1176 4.24879 12.9526 3.03409 11.4798 2.93929L11.2941 2.93333H9.41176C9.15187 2.93333 8.94118 2.7244 8.94118 2.46667C8.94118 2.23757 9.10765 2.04703 9.32718 2.00752L9.41176 2H11.2941ZM3.76471 5.26667H11.2941C11.554 5.26667 11.7647 5.4756 11.7647 5.73333C11.7647 5.96243 11.5982 6.15297 11.3787 6.19248L11.2941 6.2H3.76471C3.50481 6.2 3.29412 5.99107 3.29412 5.73333C3.29412 5.50424 3.46059 5.3137 3.68012 5.27419L3.76471 5.26667ZM16 11.8C16 14.1196 14.1038 16 11.7647 16C9.42562 16 7.52941 14.1196 7.52941 11.8C7.52941 9.4804 9.42562 7.6 11.7647 7.6C14.1038 7.6 16 9.4804 16 11.8ZM13.4285 10.8101C13.6123 10.6278 13.6123 10.3323 13.4285 10.1501C13.2447 9.96784 12.9468 9.96784 12.763 10.1501L11.7647 11.14L10.7664 10.1501C10.5827 9.96784 10.2847 9.96784 10.1009 10.1501C9.91715 10.3323 9.91715 10.6278 10.1009 10.8101L11.0992 11.8L10.1009 12.7899C9.91715 12.9722 9.91715 13.2677 10.1009 13.4499C10.2847 13.6322 10.5827 13.6322 10.7664 13.4499L11.7647 12.46L12.763 13.4499C12.9468 13.6322 13.2447 13.6322 13.4285 13.4499C13.6123 13.2677 13.6123 12.9722 13.4285 12.7899L12.4302 11.8L13.4285 10.8101Z" fill="#242424"/>
        </symbol>
    </svg>`;
